angular.module("app")
    .controller("WeatherController", function ($scope, $location, _calendar, _weather, _weatherRowDisplayStateHolder, Notification,
                                               _i18n, _view, _v, _apiService, weatherService, gettextCatalog,  $q, _logicService, _flashService,
                                                _formatService
    ) {

        var EPS = 1e-12;
        $scope.w = {
            row: {
                isActive: _weatherRowDisplayStateHolder.isActive
            },
            ps: {},
            isLoading: true
        };

        $scope.absUrl = $location.absUrl();

        $scope.$on("_view:urlReady", function () {
            var successMessage;
            if (successMessage = _flashService.get("successMessage")) {
                Notification.success(successMessage);
            }
            var failMessage;
            if (failMessage = _flashService.get("failMessage")) {
                Notification.error(failMessage);
            }

            selectField(); //selects new field or refreshColumns()
        });

        $scope.$on("_view:fieldChanged", function () {
            selectField(); //selects new field or refreshColumns()
        });

        $scope.$on("_view:panelFieldChanged", function () {
            refreshColumns();
        });

        $scope.$on("_calendar:changed", function () {
            refreshColumns();
        });

        $scope.$on("_calendar:width-changed", _.debounce(buildTempChart, 200));

        $scope.$on("aside:opened", function () {
            $scope.w.isBlackout = true;
        });

        $scope.$on("aside:closed", function () {
            $scope.w.isBlackout = false;
        });

        var sendDebouncedRequest = _.debounce(function(fieldId, requestId) {
            _calendar.itemsPromise.then(function(items) {

                $scope.w.precipitations = [];
                $scope.w.soilMoistures = [];
                $scope.w.tempChart = [];

                if (!items[0]) { //null items check
                    $scope.w.isLoading = false;
                    return;
                }
                _apiService.weatherSnapshotsAsync(_view.getCurrentAccount(), fieldId, _calendar.granularity, items[0].date, _calendar.itemsCount).then(
                    d => {
                        if ($scope.requestId != requestId) {
                            return;
                        }

                        var columns = d.data.snapshots;

                        /**
                         * @param c - single column
                         * @param c.cloudCover
                         * @param c.dayLight
                         * @param c.dewPoint
                         * @param c.humidityRel
                         * @param c.leafWetness
                         * @param c.precipAmount
                         * @param c.precipProb
                         * @param c.precipType
                         * @param c.timestamp
                         * @param c.windDir
                         * @param c.windSpeed
                         */
                        columns.forEach((c, i) => {
                            c.id = i;
                            c.calendarItem = items[i];

                            /**
                             * Round values
                             */
                            c.precipProb = _.isUndefined(c.precipProb) ? 'n/a' : round(c.precipProb * 100, 2);
                            c.solarNet = _.isUndefined(c.solarNet) ? 'n/a' : round(c.solarNet, 0);
                            c.windDir = _.isUndefined(c.windDir) ? 'n/a' : c.windDir;
                            c.windSpeed = _.isUndefined(c.windSpeed) ? 'n/a' : round(c.windSpeed, 2);
                            c.humidityRel = _.isUndefined(c.humidityRel) ? 'n/a' : round(c.humidityRel * 100, 2);
                            c.evap = _.isUndefined(c.evap) ? 'n/a' : c.evap;
                            c.landSurfTemp = _.isUndefined(c.landSurfTemp) ? 'n/a' : round(c.landSurfTemp, 0);
                            c.dewPoint = _.isUndefined(c.dewPoint) ? 'n/a' : round(c.dewPoint, 0);
                            c.leafWetness = _.isUndefined(c.leafWetness) ? 'n/a' : round(c.leafWetness, 0);
                            c.soilTemp = _.isUndefined(c.soilTemp) ? 'n/a' : round(c.soilTemp, 0);
                            c.airTempMin = round(c.airTempMin, 0);
                            c.airTempMax = round(c.airTempMax, 0);
                            c.airTemp = round(c.airTemp, 0); //h granularity

                            /**
                             * Normalize c.precipType
                             */

                            var precipType = c.precipType;

                            // Sort alphabetically and split by "-"
                            // For example: "sleet,hail,rain" becomes "hail-rain-sleet"
                            if (precipType) {
                                precipType = precipType.split(',');
                                precipType = _.sortBy(precipType, type => type);
                                precipType = precipType.join('-');
                            }

                            if (!precipType && !c.cloudCover) {
                                c.precipType = "empty";
                            } else if ((precipType == "sunny" || !precipType) && c.cloudCover <= 0.5) {
                                c.precipType = "sunny";
                            } else if ((precipType == "sunny" || !precipType) && c.cloudCover > 0.5) {
                                c.precipType = "cloudy";
                            } else {
                                c.precipType = precipType;
                            }

                            if (_calendar.granularity == 'h' && c.dayLight != 'light' && c.precipType == 'sunny') {
                                c.precipType = "moon";
                            }
                        });

                        $scope.w.columns = columns;

                        buildStages(); //find stage for each column, adds stage field in each $scope.w.columns
                        weatherService.buildPrecipitationBars(columns, $scope);
                        buildMoistureBars();
                        buildTempChart();

                        $scope.w.isLoading = false;
                    },
                    () => {
                        $scope.w.isLoading = false;
                        Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                    }
                );
            });
        }, 300);

        // // 79.29166666666666 -> 79.292
        function round(value, decimals) {
            decimals = decimals === undefined ? 3 : decimals;
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        }

        function buildStages() {
            var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;
            _apiService.fieldCrops(_view.getCurrentAccount(), currentFieldId, false, true).then(function(d) {
                if (_.size(d.data) > 0) {
                    // Loop through columns
                    $scope.w.columns.forEach(column => {
                        if (column.timestamp) { //stage granularity columns can have empty timestamp
                            var t = moment.utc(column.timestamp).startOf('day');
                            var crop = _logicService.findCropNow(t, t, d.data);
                            if (crop) {
                                _.each(crop.stages, function (stage) {
                                    if (t.isBetween(moment.utc(stage.startDate), moment.utc(stage.endDate), null, "[]")) {
                                        column.stage = stage;
                                    }
                                });
                            }
                        }
                    });
                }
            });
        }

        function buildMoistureBars() {
            var soilMoistures = [];

            $scope.w.columns.forEach(item => {
                soilMoistures.push({
                    value1: item.soilMoist1,
                    value2: item.soilMoist2,
                    value3: item.soilMoist3,
                    value4: item.soilMoist4
                });
            });

            $scope.w.soilMoistureColors = [
                '#4c9a82',
                '#5a9577',
                '#67906e',
                '#748b63',
                '#818658',
                '#8e804e',
                '#9d7c45',
                '#a97639',
                '#b7712f',
                '#c46c24'
            ];

            function chooseColor(value) {
                if (value >= 0.91) {
                    return $scope.w.soilMoistureColors[0];
                } else if (value >= 0.81) {
                    return $scope.w.soilMoistureColors[1];
                } else if (value >= 0.71) {
                    return $scope.w.soilMoistureColors[2];
                } else if (value >= 0.61) {
                    return $scope.w.soilMoistureColors[3];
                } else if (value >= 0.51) {
                    return $scope.w.soilMoistureColors[4];
                } else if (value >= 0.41) {
                    return $scope.w.soilMoistureColors[5];
                } else if (value >= 0.31) {
                    return $scope.w.soilMoistureColors[6];
                } else if (value >= 0.21) {
                    return $scope.w.soilMoistureColors[7];
                } else if (value >= 0.11) {
                    return $scope.w.soilMoistureColors[8];
                } else if (value >= 0) {
                    return $scope.w.soilMoistureColors[9];
                } else {
                    return null;
                }
            }

            soilMoistures.forEach(item => {
                item.color1 = chooseColor(item.value1);
                item.color2 = chooseColor(item.value2);
                item.color3 = chooseColor(item.value3);
                item.color4 = chooseColor(item.value4);
            });

            $scope.w.soilMoistures = soilMoistures;

            $scope.w.soilMoistureAttach = function($event) {
                if ($scope.w.ps.$selectorStart || $scope.w.ps.$selectorEnd) {
                    $scope.w.soilMoistureColorsVisible = false;
                    return;
                }

                if ($event.type == 'mouseenter') {
                    var $target = $($event.target);
                    $scope.w.soilMoistureColorsVisible = true;
                    $scope.w.soilMoistureColorsPosition = $target.offset().left + $target.width() + 10;
                } else {
                    $scope.w.soilMoistureColorsVisible = false;
                    $scope.w.soilMoistureColorsPosition = -1000; //invisible panel can still cover other elements (precipitation columns)
                }
            };

            $scope.w.soilMoistureCalculate = function($event) {
                var $target = $($event.target);

                $scope.w.soilMoistureColorsScale = $target.data('percentage')
            };
        }

        function buildTempChart() {
            $scope.w.tempChart = [];

            var browserWidth = document.body.clientWidth;
            var graphHeight = document.getElementById('graph').clientHeight;

            var maxAir;
            var minAir;

            if (_calendar.granularity != 'h') {
                maxAir = _.max($scope.w.columns, function(c){ return c.airTempMax; }).airTempMax;
                minAir = _.min($scope.w.columns, function(c){ return c.airTempMin; }).airTempMin;
            } else {
                maxAir = _.max($scope.w.columns, function(c){ return c.airTemp; }).airTemp;
                minAir = _.min($scope.w.columns, function(c){ return c.airTemp; }).airTemp;
            }

            if (_view.getCurrentUser().tempScale == "F") {
                maxAir = 32 + maxAir * 1.8;
                minAir = 32 + minAir * 1.8;
            }


            var promisesArray = [];
            _.each($scope.w.columns, function(c) {
                if (c.calendarItem) {
                    var date = shiftToMiddle(c.calendarItem);

                    var row = {};
                    if (_calendar.granularity != 'h') {

                        if (!_.isNaN(c.airTempMin) && !_.isNaN(c.airTempMax)) {

                            var l = c.airTempMin;
                            var h = c.airTempMax;

                            var airLowText = round(l, 0).toString() + gettextCatalog.getString("℃");
                            var airHighText = round(h, 0).toString() + gettextCatalog.getString("℃");

                            if (_view.getCurrentUser().tempScale == "F") {
                                h = 32 + h * 1.8;
                                l = 32 + l * 1.8;
                                airLowText = round(l, 0).toString() + gettextCatalog.getString("℉");
                                airHighText = round(h, 0).toString() + gettextCatalog.getString("℉");
                            }

                            promisesArray.push(_calendar.getPositionForDatePromise(date));

                            row = {
                                date: date,
                                airLowText: airLowText,
                                airHighText: airHighText,
                                airHighPixels: pointInsideMinMax(graphHeight, minAir, maxAir, h),
                                airLowPixels: pointInsideMinMax(graphHeight, minAir, maxAir, l)
                            };

                            $scope.w.tempChart.push(row);
                        }
                    } else {
                        if (!_.isNaN(c.airTemp)) {
                            var t = c.airTemp;
                            var tText = round(t, 0).toString() + gettextCatalog.getString("℃");

                            if (_view.getCurrentUser().tempScale == "F") {
                                t = 32 + t * 1.8;
                                tText = round(t, 0).toString() + gettextCatalog.getString("℉");
                            }

                            promisesArray.push(_calendar.getPositionForDatePromise(date));

                            row = {
                                airTempText: tText,
                                airTempPixels: pointInsideMinMax(graphHeight, minAir, maxAir, t)
                            };

                            $scope.w.tempChart.push(row);
                        }
                    }


                }
            });

            $scope.w.points = "";
            $scope.w.allNApoints = "";

            $q.all(promisesArray).then(function(values) {

                _.each(values, function (pos, i) {
                    $scope.w.tempChart[i].x = round((browserWidth / 100) * pos);
                });


                if ($scope.w.tempChart.length > 0) {
                    if (_calendar.granularity != 'h') {
                        $scope.w.points = "0," + $scope.w.tempChart[0].airHighPixels + " "; // start point top. Start at X = 0 Y = airHighPixels
                        for (var i = 0; i < $scope.w.tempChart.length; i++) {
                            $scope.w.points += $scope.w.tempChart[i].x + "," + $scope.w.tempChart[i].airHighPixels + " "; // draw top line
                        }
                        $scope.w.points += browserWidth + "," + $scope.w.tempChart[$scope.w.tempChart.length - 1].airHighPixels + " "; // end point top. End at X = Browser Width
                        $scope.w.points += browserWidth + "," + $scope.w.tempChart[$scope.w.tempChart.length - 1].airLowPixels + " "; // start point bottom. Start at X = Browser Width
                        for (var i = $scope.w.tempChart.length - 1; i >= 0; i--) {
                            $scope.w.points += $scope.w.tempChart[i].x + "," + $scope.w.tempChart[i].airLowPixels + " ";  // draw bottom line
                        }
                        $scope.w.points += "0," + $scope.w.tempChart[0].airLowPixels; // end point bottom
                    } else {
                        $scope.w.points = "0," + $scope.w.tempChart[0].airTempPixels + " ";
                        for (var i = 0; i < $scope.w.tempChart.length; i++) {
                            $scope.w.points += $scope.w.tempChart[i].x + "," + $scope.w.tempChart[i].airTempPixels + " ";
                        }
                        $scope.w.points += browserWidth + "," + $scope.w.tempChart[$scope.w.tempChart.length - 1].airTempPixels;
                    }
                } else { // ----nan----nan---nan---nan--- chart
                    _.each($scope.w.columns, function(c) {
                        var date = shiftToMiddle(c.calendarItem);

                        _calendar.getPositionForDatePromise(date).then(function(pos) {
                            $scope.w.tempChart.push({
                                x: round((browserWidth / 100) * pos),
                                airTempText: "n/a",
                                airTempPixels: pointInsideMinMax(graphHeight)
                            });
                        });
                    });

                    var y = pointInsideMinMax(graphHeight);
                    $scope.w.allNApoints = "0," + y +" " + browserWidth + "," + y;
                }
            });
        }

        function pointInsideMinMax(graphHeight, min = 0, max = 0, val = 0) {
            var k = 0.5; //default is middle
            if (min && max && Math.abs(max-min) > EPS) {
                k = (max - val) / (max - min);
            }
            return  round(14 + (graphHeight - 26) * k);
        }

        function shiftToMiddle(calendarItem) {
            var columnTotalDuration = calendarItem.dateFinish.diff(calendarItem.date);
            return calendarItem.date.clone().add(columnTotalDuration / 2, 'ms');
        }

        /**
         * Set a field if none was set
         */
        function selectField() {
            if (!_view.isFieldSelected() && !_view.isFarmAndFieldSelected() && !_view.isFarmSelected()) {
                if (_view.getNavigation().farms.length > 0) {
                    _v.change({set: {field: undefined, pfield: _view.getNavigation().farms[0].fields[0].id, farm: _view.getNavigation().farms[0].id}});
                    return;
                } else if (_view.getNavigation().standaloneFields.length > 0) {
                    _v.change({set: {field: _view.getNavigation().standaloneFields[0].id, pfield: undefined, farm: undefined}});
                    return;
                }
            } else if(_view.isFarmSelected() && !_view.getPanelField()) {
                if (_view.getCurrentFarm().fields.length > 0) {
                    _v.change({set: {pfield: _view.getCurrentFarm().fields[0].id}});
                    return;
                }
            }
            refreshColumns();
        }

        function refreshColumns() {

            var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : (_view.isPanelFieldSelected() ? _view.getPanelField().id : null);

            if (currentFieldId) {
                $scope.w.isLoading = true;
                $scope.w.columns = [];
                $scope.w.points = "";
                $scope.w.granularity = _calendar.granularity;

                $scope.requestId = _.uniqueId();
                sendDebouncedRequest(currentFieldId, $scope.requestId);
            } else {
                $scope.w.isLoading = false;
            }
        }
    })
    .directive("wWeather", function() {
        return {
            restrict: "E",
            templateUrl: "t-w-weather",
            scope: {
                c: "=",
                row: "="
            }
        }
    })
    .directive("wWind", function() {
        return {
            restrict: "E",
            templateUrl: "t-w-wind",
            scope: {
                c: "=",
                row: "="
            }
        }
    })
    .directive("wHumidity", function() {
        return {
            restrict: "E",
            templateUrl: "t-w-humidity",
            scope: {
                c: "=",
                row: "="
            }
        }
    })
    .directive("wEvapoTrans", function() {
        return {
            restrict: "E",
            templateUrl: "t-w-evapo-trans",
            scope: {
                c: "=",
                row: "="
            }
        }
    })
    .directive("wTemps", function() {
        return {
            restrict: "E",
            templateUrl: "t-w-temps",
            scope: {
                c: "=",
                row: "="
            }
        }
    })
    .directive("wPlant", function() {
        return {
            restrict: "E",
            templateUrl: "t-w-plant",
            scope: {
                c: "="
            }
        }
    })
    .directive("wSoil", function() {
        return {
            restrict: "E",
            templateUrl: "t-w-soil",
            scope: {
                c: "=",
                row: "="
            }
        }
    })
    .directive("cAsideWInfo", function(_i18n, _weather) {
        return {
            restrict: "E",
            templateUrl: "t-c-aside-w-info",
            replace: true,
            scope: {
                close: "="
            },
            bindToController: true,
            controllerAs: 'vm',
            controller: function() {
                var vm = this;

                vm.toggle = toggle;
                vm.weatherDisplayItems = getWeatherDisplayItems();

                function toggle(item) {
                    _weather.rows.toggle(item.row.id);
                }

                function getWeatherDisplayItems() {
                    return _weather.rows.get().map(row => {
                        return {
                            row: row,
                            icon: row.id,
                            label: _i18n.getString("weather.rows." + row.id)
                        }
                    });
                }
            }
        }
    })
    .directive("cAsideForecastDiseases", function(_i18n, _weather, _view, $rootScope, _diseasesForecastService) {
        return {
            restrict: "E",
            templateUrl: "t-c-aside-forecast-diseases",
            replace: true,
            controllerAs: 'vm1',
            controller: function() {
                var vm1 = this;
                vm1.view = _view;

                $rootScope.$on("_calendar:width-changed", function() {
                    if (_view.is.forecastOpened() && _view.getForecastData() != null) {
                        _diseasesForecastService.chart(_view.getForecastData());
                    }
                });

                $rootScope.$on("_calendar:changed", () => {
                    if (_view.is.forecastOpened() && _view.getForecastData() != null) {
                        _diseasesForecastService.chart(_view.getForecastData());
                    }
                });

                $rootScope.$on("_view:forecastChanged", () => {
                    $rootScope.$safeApply(function() {
                        vm1.items = getDisplayItems();
                        if (_view.is.forecastOpened() && _view.getForecastData() != null) {
                            _diseasesForecastService.chart(_view.getForecastData());
                        } else {
                            _diseasesForecastService.clearChart();
                        }
                    });
                });

                vm1.close = function () {
                    _view.setForecastOpened(false);
                };

                vm1.toggle = function(item) {
                    _.each(vm1.items, function (i) {
                        if (i.id == item.id) {
                            i.isActive = true;
                            _view.setForecastData(_.find(_view.getForecastAll(), function (e) { return e.disease.id == item.id}));
                            _diseasesForecastService.chart(_view.getForecastData());
                        } else {
                            i.isActive = false;
                        }
                    });
                };

                function getDisplayItems() {
                    return _view.getForecastAll().map(row => {
                        return {
                            isActive: _view.getForecastData() != null && _view.getForecastData().disease.id == row.disease.id,
                            label: row.disease.formName,
                            id: row.disease.id
                        }
                    });
                }
            }
        }
    })
    .directive('precipitationsSelector', function(_view) {
        return {
            controller: function($scope, $element, $rootElement, _formatService) {
                var $calendarNavigation = $rootElement.find('.c-calendar--next, .c-calendar--prev');
                var $selector = $element.find('#precipitations-selector');

                // Start selecting when clicked on $element (which is svg)
                $element.on('mousedown', onMouseDown);

                $scope.dynamicPopover = {
                    isOpen: false,
                    units: _view.getCurrentUser().units
                };

                // shortcuts
                var $selectorStartPoint = $scope.w.ps.$selectorStartPoint;
                var $selectorStart = $scope.w.ps.$selectorStart;
                var $selectorEnd = $scope.w.ps.$selectorEnd;

                // Restore previous selection or init variables
                if ($selectorStartPoint) {
                    // Restore values
                    drawSelector();
                    onMouseUp();
                } else {
                    // Set initial values
                    $selectorStartPoint = 0; // initial mouseDown position
                    $selectorStart = 0;
                    $selectorEnd = 0;
                }

                var xPrev = 0,
                    movingTo = 'right';

                /**
                 * On Mouse Down
                 *
                 * @param {Event} $event
                 */
                function onMouseDown($event) {
                    $rootElement.on('mousemove.precipitationsSelector', onMouseMove); // Bind MouseMove event to document - to do selection
                    $rootElement.on('mouseup.precipitationsSelector', onMouseUp); // Bind MouseUp event to document - to stop selection
                    resetSizeAndPosition(); // Reset $selector's size and position
                    $selectorStartPoint = $event.clientX; // Save initial (start) point on click

                    // Disable "MouseDown" Event handler
                    $element.off('mousedown', onMouseDown);
                }

                /**
                 * On Mouse Up
                 */
                function onMouseUp() {
                    $rootElement.off('.precipitationsSelector'); // Unbind all events by namespace

                    // Reset selection on simple click (or if selection is empty)
                    if ($selectorStart == $selectorEnd) {
                        resetSizeAndPosition();
                        drawSelector();
                    } else {
                        setTimeout(() => { // wait for UI refresh
                            $rootElement.on('click.precipitationsSelector', resetSelector); // Bind Click Event to reset $selector by clicking outside
                        }, 0);

                        calculatePrecipitation(); // Calculate selected Precipitation
                    }

                    // Enable "MouseDown" Event handler
                    $element.on('mousedown', onMouseDown);
                }

                /**
                 * On Mouse Move
                 * Calculate $selector's size and position
                 *
                 * @param {Event} e
                 */
                function onMouseMove(e) {
                    // Find out mouse move direction
                    if (!xPrev) { xPrev = e.clientX; }
                    movingTo = xPrev > e.clientX ? 'left' : xPrev < e.clientX ? 'right' : 'stop';
                    xPrev = e.clientX;

                    // console.log(e);

                    if (movingTo == 'right') {
                        if ($selectorStart != $selectorStartPoint) {
                            // Moving from left to right
                            if ($selectorStart > $selectorStartPoint) {
                                $selectorStart = $selectorStartPoint;
                                $selectorEnd = e.clientX - $selectorStart;
                            } else {
                                $selectorStart = e.clientX;
                                $selectorEnd = $selectorStartPoint - e.clientX;

                                // Handle possible errors
                                if ($selectorEnd < 0) {
                                    $selectorEnd = 0;
                                }
                            }
                        } else {
                            // Moving from stop to right
                            $selectorStart = $selectorStartPoint;
                            $selectorEnd = e.clientX - $selectorStart;
                        }
                        drawSelector();
                    } else if (movingTo == 'left') {
                        if ($selectorStart == $selectorStartPoint && $selectorEnd > 0) {
                            // Moving from right to left
                            $selectorStart = $selectorStartPoint;
                            $selectorEnd = e.clientX - $selectorStart;

                            // Handle possible errors
                            if ($selectorEnd < 0) {
                                $selectorEnd = 0;
                            }
                        } else {
                            // Moving from stop to left
                            $selectorStart = e.clientX;
                            $selectorEnd = $selectorStartPoint - (e.clientX);
                        }
                        drawSelector();
                    }
                }

                /**
                 * Draw $selector
                 */
                function drawSelector() {
                    // Hide soilMoisture scale
                    $scope.$safeApply(() => $scope.w.soilMoistureColorsVisible = false);

                    // Save values to $scope
                    $scope.w.ps.$selectorStartPoint = $selectorStartPoint;
                    $scope.w.ps.$selectorStart = $selectorStart;
                    $scope.w.ps.$selectorEnd = $selectorEnd;

                    $selector.css('left', $selectorStart);
                    $selector.css('width', $selectorEnd);
                }

                /**
                 * Reset $selector's size and position
                 */
                function resetSizeAndPosition() {
                    $scope.w.ps.$selectorStartPoint = $selectorStartPoint = 0;
                    $scope.w.ps.$selectorStart = $selectorStart = 0;
                    $scope.w.ps.$selectorEnd = $selectorEnd = 0;
                    xPrev = 0;
                    $scope.$safeApply(() => $scope.dynamicPopover.isOpen = false);
                }

                /**
                 * Completely reset $selector on outside click
                 *
                 * @param {Event} e
                 */
                function resetSelector(e) {
                    var $target = $(e.target);

                    if (!$target.is($element) && !$target.is($calendarNavigation)
                        && $element.has($target).length === 0 && $calendarNavigation.has($target).length === 0) {
                        resetSizeAndPosition();
                        drawSelector();
                        $rootElement.off('.precipitationsSelector'); // Unbind all events by namespace
                    }
                }

                function calculatePrecipitation() {
                    // $selector's size and positions are set in pixels. We need percentage
                    var $selectorStartPercentage = 100 * $scope.w.ps.$selectorStart / document.body.clientWidth;
                    var $selectorEndPercentage = 100 * ($scope.w.ps.$selectorStart + $scope.w.ps.$selectorEnd) / document.body.clientWidth;

                    // Filter selected precipitations
                    var selectedPrecipitations = $scope.w.precipitations.filter(element => {
                        var elementStart = element.x;
                        var elementEnd = element.x + element.width;

                        // Element completely fits to range
                        var elementBodyFits = elementStart > $selectorStartPercentage && // Element starts later than Range starts
                            elementEnd < $selectorEndPercentage; // Element ends sooner than Range ends

                        // Element Start fits to range
                        var elementStartFits = elementStart < $selectorEndPercentage && // Element starts before Range ends
                            elementEnd > $selectorEndPercentage; // Element ends after Range ends

                        // Element end fits to range
                        var elementEndFits = elementStart < $selectorStartPercentage && // Element starts after Range starts
                            elementEnd > $selectorStartPercentage; // Element ends after Range starts

                        return elementBodyFits || elementStartFits || elementEndFits;
                    });

                    // Display info if we have selected 2 and more precipitations
                    displayPrecipitation(selectedPrecipitations);
                }

                function displayPrecipitation(precipitations) {
                    if (!precipitations.length) {
                        $scope.dynamicPopover.noData = true;
                        $scope.$safeApply(() => $scope.dynamicPopover.isOpen = true);
                        return;
                    }

                    var from = moment.utc(precipitations[0].moment).startOf('day');
                    var to = moment.utc(precipitations[precipitations.length-1].moment).startOf('day');

                    // Total
                    var total = round(_.reduce(precipitations, function(memo, precipitation){ return memo + precipitation.value; }, 0), 2);

                    // Daily Average
                    var days = Math.abs(from.diff(to, 'days')) + 1;
                    var dailyAverage = round(total / days, 2);

                    $scope.dynamicPopover.noData = false;
                    if (days == 1) {
                        $scope.dynamicPopover.title = _formatService.formatDate(from);
                    } else {
                        $scope.dynamicPopover.title = _formatService.formatDate(from) + ' - ' + _formatService.formatDate(to);
                    }
                    $scope.dynamicPopover.total = total;
                    $scope.dynamicPopover.average = dailyAverage;
                    $scope.$safeApply(() => $scope.dynamicPopover.isOpen = true);
                }

                // // 79.29166666666666 -> 79.292
                function round(value, decimals) {
                    decimals = decimals === undefined ? 3 : decimals;
                    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
                }
            }
        }
    });